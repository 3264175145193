import { Button } from "@mui/material";
import { signIn } from "next-auth/react";
import Image from "next/image";

export function StravaButton() {
  return (
    <Button
      onClick={(e) => {
        e.preventDefault();
        signIn('strava');
      }}
    >
      <Image src="/img/btn-strava.svg" alt="connect with strava" width={386} height={96} />
    </Button>
  )
}