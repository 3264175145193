import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';

export default function ControlledAccordion({ questions = [] }) {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {questions.map((item) => (
        <Accordion key={item.question} expanded={expanded === item.question} onChange={handleChange(item.question)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography variant="h4" color="text.secondary" sx={item.sub ? { width: '33%', flexShrink: 0 } : {}}>
              {item.question}
            </Typography>
            {item.sub && (
              <Typography sx={{ color: 'text.secondary' }}>{item.sub}</Typography>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h6" component="div">
              {item?.answerComponent ? item.answerComponent() : item.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
