import { Link } from '@mui/material';
import { Box } from '@mui/system';

export default function Footer() {
  return (
    <Box mt={4} p={4} textAlign="center">
      © ViteBike
      {' '}
      {new Date().getFullYear()}
      {' – '}
      <Link href="/support">Support ViteBike</Link>
    </Box>
  );
}
