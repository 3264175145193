import { LinearProgress } from '@mui/material';
import { Box } from '@mui/system';

export default function Loading() {
  const styles = {
    width: '100%', display: 'block', marginTop: 2,
  };
  return (
    <Box sx={{ width: '100%', maxWidth: 300 }}>
      <LinearProgress sx={styles} color="secondary" />
      <LinearProgress sx={styles} color="primary" />
      <LinearProgress sx={styles} color="secondary" />
    </Box>
  );
}
