import {
  Avatar, Button, Chip, Grid, Link, Menu, MenuItem, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { MdWbSunny, MdBrightness2 } from 'react-icons/md';
import { RiBikeFill } from 'react-icons/ri';
import { GiFat } from 'react-icons/gi';
import { Box } from '@mui/system';
import { signOut, useSession } from 'next-auth/react';
import React from 'react';
import { ColorModeContext } from 'pages/_app';
import NextLink from 'next/link';
import Image from 'next/image';

function UserInfo({ name, src }: { name: any, src: any }) {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        role="button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {smUp && (
          <span>
            👋
            {' '}
            {name}
            {' '}
          </span>
        )}
        <Avatar
          alt={name}
          src={src}
          sx={{
            width: 30, height: 30, display: 'inline-block', verticalAlign: 'middle', marginLeft: '10px',
          }}
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => signOut()}>Logout</MenuItem>
        <MenuItem>
          <NextLink href="/dashboard/settings" style={{ textDecoration: 'none' }}>
            <Link sx={{ color: '#fff', textDecoration: 'none' }}>
              Settings
            </Link>
          </NextLink>
        </MenuItem>
      </Menu>
    </div>
  );
}

function Header() {
  const session = useSession();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
    >
      <Grid item>
        <Link href="/" underline="none">
          <RiBikeFill size={40} color={theme.palette.primary.main} />
        </Link>
      </Grid>
      <Grid item>
        <Typography variant="overline" component="div" position="relative" display="block" fontSize={20}>
          ViteBike
          {' '}
          {smUp ? (
            <>
              <span>• by FatKid</span>
              <Box display="inline-block" sx={{ position: 'absolute', top: 2 }}>
                <GiFat size={22} color={theme.palette.secondary.light} />
              </Box>
            </>
          ) : null}
        </Typography>
      </Grid>
      <Grid item flexGrow={1} />
      <Grid item>
        {session.status === 'authenticated' ? <UserInfo name={session.data.user.name} src={session.data.user.image} /> : <div />}
      </Grid>
      <Grid item>
        <Image src="/img/strava-power.png" alt="logo" width={199} height={86} />
      </Grid>
    </Grid>
  );
}

export default Header;
