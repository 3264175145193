import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  TextField,
  Button,
  CircularProgress,
  Alert,
  AlertTitle,
  Typography,
  Box,
} from '@mui/material';
import { contactSchema } from '@/lib/validation-schema';

interface ContactFormData {
  name: string;
  email: string;
  message: string;
}

export default function ContactForm({ csrfToken }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactFormData>({
    resolver: yupResolver(contactSchema),
  });

  const onSubmit = async (data: ContactFormData) => {
    setIsSubmitting(true);
    setSubmitError(null);
    setSubmitSuccess(false);

    try {
      const res = await fetch('/api/contact', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'x-csrf-token': csrfToken,
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSubmitSuccess(true);
    } catch (err: any) {
      setSubmitError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box maxWidth={600} sx={{ mx: 'auto' }}>
      <Typography variant="h2" component="h2" textAlign="center">
        Say, Hello!
      </Typography>
      <Typography variant="body2" sx={{ textAlign: 'center' }} mb={4}>
        {'(Be nice, I\'m a delicate flower)'}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          sx={{ marginBottom: 2 }}
          label="Name"
          fullWidth
          error={Boolean(errors.name)}
          helperText={errors.name?.message}
          {...register('name')}
        />

        <TextField
          sx={{ marginBottom: 2 }}
          label="Email"
          fullWidth
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
          {...register('email')}
        />

        <TextField
          sx={{ marginBottom: 2 }}
          label="Message"
          fullWidth
          multiline
          rows={4}
          error={Boolean(errors.message)}
          helperText={errors.message?.message}
          {...register('message')}
        />

        {submitError && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {submitError}
          </Alert>
        )}

        {submitSuccess && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            Your message has been sent!
          </Alert>
        )}

        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isSubmitting}
          fullWidth
          sx={{ mt: 2 }}
          size="xlarge"
        >
          {isSubmitting ? (
            <CircularProgress size={24} color="primary" />
          ) : (
            'Send Message'
          )}
        </Button>
      </form>
    </Box>
  );
}
