import { getCsrfToken, getSession } from 'next-auth/react';
import HomeLoggedOut from 'components/home-logged-out';
import LayoutPublic from '@/components/layout-public';

export default function Home({ csrfToken }) {
  return (
    <LayoutPublic loading={false}>
      <HomeLoggedOut csrfToken={csrfToken} />
    </LayoutPublic>
  );
}

export async function getServerSideProps(context: any) {
  const session = await getSession(context);
  const csrfToken = await getCsrfToken(context);
  console.log('session ➔', session);

  if (session) {
    return {
      redirect: {
        destination: '/dashboard/summary',
        permanent: false,
      },
    };
  }

  return {
    props: { csrfToken },
  };
}
