import Link from 'next/link';

export default function AboutMe() {
  const styles = {
    display: 'block',
    marginTop: 2,
    color: '#fff',
  };
  return (
    <>
      <p>
        ViteBike is a side project of mine. I am a software engineer by trade and a cyclist by passion. I built this site to see my Strava data in a different way and to learn some new technologies. I hope you enjoy it.
      </p>
      <p>
        You can find me elsewhere on the web:
      </p>
      <Link style={styles} href="https://www.strava.com/athletes/533982" target="_blank" rel="noopener noreferrer">Strava</Link>
      <Link style={styles} href="https://www.justinwhall.com" target="_blank" rel="noopener noreferrer">Personal Website</Link>
      <Link style={styles} href="https://www.instagram.com/i_m_fat_kid/" target="_blank" rel="noopener noreferrer">Instagram</Link>
      <Link style={styles} href="https://www.instagram.com/justinwhall/" target="_blank" rel="noopener noreferrer">Twitter</Link>
      <Link style={styles} href="https://www.linkedin.com/in/justinwindsorhall/" target="_blank" rel="noopener noreferrer">LinkedIn</Link>
    </>
  );
}
