import Typography from '@mui/material/Typography';
import Image from 'next/image';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material';

export default function CardTitleDesc({ title, desc, img }) {
  const theme = useTheme();
  return (
    <Box sx={{
      boxShadow: theme.shadows[5],
    }}
    >
      <Image src={img} alt="connect with strava" width={1806} height={1126} layout="responsive" />
      <Box p={2}>
        <Typography gutterBottom variant="h5" component="div" color="primary">
          {title}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {desc}
        </Typography>
      </Box>
    </Box>
  );
}
