import {
  Container, useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import Box from '@mui/material/Box';
import Loading from './loading';
import Header from './header';
import Footer from './footer';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme }) => {
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  return ({
    flexGrow: 1,
    // height: '80vh',
    maxHeight: '100%',
    maxWidth: '100%',
    minWidth: smUp ? 0 : '100%',
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  });
});

function LayoutPublic({ children, loading }: { children: React.ReactNode, loading: boolean }) {
  return (
    <Box sx={{ display: 'block' }}>
      <Container>
        <Header />
      </Container>
      <Main>
        {loading ? (
          <Box
            sx={{
              height: '90vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Loading />
          </Box>
        ) : children}
      </Main>
      <Footer />
    </Box>
  );
}

export default LayoutPublic;
