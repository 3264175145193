import AboutMe from '@/components/about-me';

export const COOKIE_INACTIVE = 'vitebike.inactive';
export const STRAVA_CLIENT_ID = '7694';
export const STRAVA_REDIRECT_URL = 'http://localhost:3000/strava-connect';
export const STRAVA_SCOPE = 'activity:read_all,profile:read_all';
export const STRAVA_EXCHANGE_TOKEN_URL = 'https://www.strava.com/api/v3/oauth/token';
export const STRAVA_COOKIE_NAME = 'vitebike.strava';
export const STRAVA_COOKIE_IS_USER_SWITCHED = 'vitebike.userSwitched';
export const STRAVA_TOKEN_HEADER = 'vitebike-strava-token';
export const STRAVA_TOKEN_HEADER_TOKEN_ERROR = 'vitebike-strava-token-error';
export const STRAVA_REFRESH_OFFSET = 500;
export const STRAVA_COOKIE_NO_ACTIVITIES = 'vitbike.noActivities';
export const STRAVA_ACTIVITY_TYPES: string[] = [
  'AlpineSki',
  'BackcountrySki',
  'Canoeing',
  'Crossfit',
  'EBikeRide',
  'Elliptical',
  'Golf',
  'Handcycle',
  'Hike',
  'IceSkate',
  'InlineSkate',
  'Kayaking',
  'Kitesurf',
  'NordicSki',
  'Ride',
  'RockClimbing',
  'RollerSki',
  'Rowing',
  'Run',
  'Sail',
  'Skateboard',
  'Snowboard',
  'Snowshoe',
  'Soccer',
  'StairStepper',
  'StandUpPaddling',
  'Surfing',
  'Swim',
  'Velomobile',
  'VirtualRide',
  'VirtualRun',
  'Walk',
  'WeightTraining',
  'Wheelchair',
  'Windsurf',
  'Workout',
  'Yoga',
];

export const STRAVA_ACTIVITY_KEY_VAL: { [key: string]: string } = {
  AlpineSki: 'Alpine Ski',
  BackcountrySki: 'Backcountry Ski',
  Canoeing: 'Canoeing',
  Crossfit: 'Crossfit',
  EBikeRide: 'E-Bike Ride',
  Elliptical: 'Elliptical',
  Golf: 'Golf',
  Handcycle: 'Handcycle',
  Hike: 'Hike',
  IceSkate: 'Ice Skate',
  InlineSkate: 'Inline Skate',
  Kayaking: 'Kayaking',
  Kitesurf: 'Kitesurf',
  NordicSki: 'Nordic Ski',
  Ride: 'Ride',
  RockClimbing: 'Rock Climbing',
  RollerSki: 'Roller Ski',
  Rowing: 'Rowing',
  Run: 'Run',
  Sail: 'Sail',
  Skateboard: 'Skateboard',
  Snowboard: 'Snowboard',
  Snowshoe: 'Snowshoe',
  Soccer: 'Soccer',
  StairStepper: 'Stair Stepper',
  StandUpPaddling: 'Stand-Up Paddleboarding',
  Surfing: 'Surfing',
  Swim: 'Swim',
  Velomobile: 'Velomobile',
  VirtualRide: 'Virtual Ride',
  VirtualRun: 'Virtual Run',
  Walk: 'Walk',
  WeightTraining: 'Weight Training',
  Wheelchair: 'Wheelchair',
  Windsurf: 'Windsurf',
  Workout: 'Workout',
  Yoga: 'Yoga',
};

export const HOME_CARDS = [
  {
    title: 'Compare your totals',
    desc: 'Or lack thereof. Either way, it looks rad.',
    img: '/img/totals.jpg',
  },
  {
    title: 'Nerd out on your activities',
    desc: 'See \'em all in one place; Filterable for your pleasure.',
    img: '/img/activities.jpg',
  },
  {
    title: 'Heatmap',
    desc: 'Visualize your activity data over time.',
    img: '/img/heatmap.jpg',
  },
  {
    title: 'Summary',
    desc: 'Get a quick overview of your activity data.',
    img: '/img/summary.jpg',
  },
  {
    title: 'Segment efforts',
    desc: 'Compare your efforts on specific segments over time.',
    img: '/img/efforts.jpg',
  },
  {
    title: 'Calendar',
    desc: 'Have a checkered past? Don\'t worry, you\'ll fit right in around here.',
    img: '/img/calendar.jpg',
  },
];

export const FAQS = [
  {
    question: 'Who is ViteBike?',
    answer: 'I am ViteBike. I am a software engineer by trade and a cyclist by passion. I built this site as a side project to see my Strava data in a different way and to learn some new technologies. I hope you enjoy it.',
    answerComponent: AboutMe,
  },
  {
    question: 'How much does this thing cost?',
    answer: "It's free. But if you want to buy me a beer, I won't say no.",
  },
  {
    question: 'How long does it take to import my activies?',
    answer: 'It depends on how many activities you have. If you have a lot, it could take a number of hours. The reason for this is Strava places a limit on however many requests you can make to their API per hour. I have to make a request for each activity.',
  },
  {
    question: 'Will you sell my data?',
    answer: 'No. I have no interest in your data. I also have no idea how to sell data. Lastly, you can delete your data at any time from my database. In fact, I encourage you to do so if you no longer want to use ViteBike as it costs money to store.',
  },
  {
    question: 'I found a bug. What do I do?',
    answer: 'Find the contact form at the bottom of the page and send me a message. Please be polite. This is a side project and I have a sensitive soul.',
  },
  {
    question: 'I have a feature request. What do I do?',
    answer: 'Find the contact form at the bottom of the page and send me a message. Please be realistic. This is a side project and I can\'t build every feature that is requested.',
  },
  {
    question: 'I\'d like to buy you a beer and/or thank you. What do I do?',
    answer: 'Eventually, I will add a way to do this. For now, find the contact form at the bottom of the page and send me a message.',
  },
  {
    question: 'What is up with this FatKid thing?',
    answer: 'It started as a joke. Cyclists are generally lanky and wirey. I am not. I also thought it would be funny if people got emails from Strava that said "A FatKid stole your KOM." That scenario rarely comes to fruition these days, but the name stuck.',
  },
];
