/* eslint-disable @next/next/no-html-link-for-pages */
import Image from 'next/image';
import {
  Box, Button, Grid, SvgIcon, Typography, useTheme,
} from '@mui/material';
import { signIn } from 'next-auth/react';
import ContactForm from '@/components/contact-form';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FAQS, HOME_CARDS } from '@/constants';
import { useRef, useState } from 'react';
import { RememberMe } from '@mui/icons-material';
import CardTitleDesc from './card-title-desc';
import ControlledAccordion from './accordion';
import FormEmail from './form-email';
import { StravaButton } from './strava-button';

export default function HomeLoggedOut({ csrfToken }) {
  const theme = useTheme();
  const ref = useRef(null);
  const [fetching, setFetching] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const scrollToRef = () => ref.current.scrollIntoView({ behavior: 'smooth' });

  const joinWaitlist = async ({ email }: { email: string | undefined }) => {
    setFetching(true);
    const res = await fetch('/api/waitlist', {
      body: JSON.stringify({
        email,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });
    const result = await res.json();

    setFetching(false);
    setSubmitted(true);
  };

  return (
    <>
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          maxWidth: 700,
          margin: '0 auto',
        }}
      >
        <div>
          <Typography variant="h1" sx={{ textAlign: 'center' }}>
            ViteBike
          </Typography>
        </div>
        <div>
          <Typography variant="h3" sx={{ textAlign: 'center' }} color={theme.palette.primary.main}>
            Strava data on steroids
          </Typography>
        </div>
        <div>
          <Typography variant="body2" sx={{ textAlign: 'center' }} color="grey.300">
            (Just say no to drugs, kids)
          </Typography>
        </div>
        {/* {submitted ? (
          <Box mt={4}>
            <Typography variant="h4" sx={{ textAlign: 'center' }} color="success.main">
              Thanks for joining the waitlist! We'll let you know when we open up again.
            </Typography>
          </Box>
        ) : (
          <>
            <Box mt={4}>
              <div>
                <Typography variant="h4" sx={{ textAlign: 'center' }} color="grey.300">
                  We are currently at capacity. Join the waitlist to be notified when we open up again.
                </Typography>
              </div>
            </Box>
            <Box mt={4}>
              <FormEmail fetching={fetching} onSubmit={joinWaitlist} submitText='Join Waitlist' styles={{
                '& button': {
                  width: '100%',
                }
              }
              } />
            </Box>
          </>
        )} */}
        <Box mt={16}>
          {/* <Typography variant="body1" sx={{ textAlign: 'center' }} color="grey.300">
            Already have an account?
          </Typography> */}
          <StravaButton />
        </Box>
        <Box mt={4}>
          <Button
            variant="text"
            sx={{ color: 'grey.200', textTransform: 'none' }}
            onClick={scrollToRef}
          >
            I'm not convinced, tell me more
          </Button>
        </Box>
        <Box>
          <SvgIcon
            component={KeyboardArrowDownIcon}
            sx={{ fontSize: 48, color: 'success.light', ':hover': { color: 'success.main', cursor: 'pointer' } }}
            onClick={scrollToRef}

          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          maxWidth: 800,
          margin: '0 auto',
        }}
      >
        <div ref={ref} />
        <Typography variant="h2" color="secondary.light" sx={{ textAlign: 'center' }}>
          Your Strava performance has never looked so good
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'center' }}>
          {'(We\'ve seen you ride)'}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          maxWidth: 1800,
          margin: '60px auto 60px auto',
        }}
      >
        <Grid container spacing={2}>
          {HOME_CARDS.map((card) => (
            <Grid
              item
              xs={12}
              lg={6}
              key={card.title}
            >
              <CardTitleDesc {...card} />
            </Grid>
          ))}
        </Grid>
        <Box mt={16}>
          <StravaButton />
        </Box>
      </Box>
      <Box
        my={16}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Box maxWidth={1000}>
          <div>
            <Typography variant="h2" sx={{ textAlign: 'center' }} color={theme.palette.success.light}>
              Still curious are we?
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'center' }}>
              (Remember what we said about drugs?)
            </Typography>
          </div>
          <Box py={4}>
            <ControlledAccordion questions={FAQS} />
          </Box>
        </Box>
        <Box mt={16}>
          <StravaButton />
        </Box>
      </Box>
      <Box>
        <ContactForm csrfToken={csrfToken} />
      </Box>
    </>
  );
}
